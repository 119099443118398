import { propOr } from 'ramda'
const RECYCLE_LOCALES = [
  'bg_BG',
  'hr_HR',
  'et_EE',
  'el_CY',
  'el_GR',
  'hu_HU',
  'lv_LV',
  'lt_LT',
  'sl_SL'
]
const getLocale: any = (localization) => {
  const country = propOr('us', 'country', localization)
  const language = propOr('en', 'language', localization)
  return { language: language, country: country }
}
export const isRecycleNotSupported = (locale) => {
  return RECYCLE_LOCALES.includes(locale)
}

export default getLocale
