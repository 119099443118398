import React, { useState, useEffect } from 'react'
import styles from './ErollmentStatusContent.module.scss'
// eslint-disable-next-line no-restricted-imports
import { Button } from '@veneer/core'
import { Stack } from '@jarvis/web-stratus-client'
//import axios from 'axios'
import { useI18n, LoaderWidget } from '@jarvis/react-portal-addons'
import {
  Divider,
  EnrolledIcon,
  IIBrandLogo,
  IIGiftIcon,
  NoDataIcon
} from './styles'
import { JarvisAuthProvider } from '@jarvis/web-http'
import useEnrollmentStatusApiCall from '../../hooks/useEnrollmentStatusApiCall'
//import { createMemoryHistory } from "history"
import { createBrowserHistory } from 'history'
//import { useGetText } from '../../
import getLocale, { isRecycleNotSupported } from '../../lib/getLocale'
export const history = createBrowserHistory()
export const defaultLandingPageURL = (stack: Stack, country, language) => {
  const countryCode = country === 'gb' ? 'uk' : country
  return (
    {
      [Stack.dev]: `https://gemini-pie.hpconnectedpie.com/${countryCode}/${language}/l/v2/business`,
      [Stack.stage]: `https://instantink-stage1.hpconnectedstage.com/${countryCode}/${language}/l/v2/business`,
      [Stack.prod]: `https://instantink.hpconnected.com/${countryCode}/${language}/l/v2/business`
    }[stack] ||
    `https://instantink-pie1.hpconnectedpie.com/${countryCode}/${language}/l/v2/business`
  )
}
export type EnrollmentStatusProps = {
  /**
   * UUID of the printer
   */
  deviceUuid: string
  /**
   * Printer Model
   */
  modelNumber: string
  /**
   * program level - hp+ , ucde
   */
  programLevel: string
  /**
   * supply type - toner or ink
   */
  supplyType?: string
  shell: any
  source?: string
  /**
   * authentication provider object that must include the getAccessToken function
   */
  authProvider?: JarvisAuthProvider

  /**
   * enum of (dev,pie,stage,prod) used for stratus web calls
   */
  stack: Stack
}

const EnrollmentStatus = (props: EnrollmentStatusProps) => {
  const [eligibility, getEligibility] = useState('')
  const [enrolled, getEnrolled] = useState('')
  const [eligibileForTrial, getEligibilityTrial] = useState('')
  const [trialRemainingDays, getTrialRemainingDays] = useState('')
  const [trialFreeMonths, getTrialFreeMonths] = useState('3')
  const [pagesPerMonth, getPagesPerMonth] = useState('1000')
  const isEnabled = false

  const authProvider = props.authProvider
  const stack = props.stack
  const deviceId = props.deviceUuid
  const isHpPlus = props.programLevel === 'HP+'
  const supplyType = props.supplyType
  const { country, language } = getLocale(props.shell.v1.localization)
  const isRecycleNotSupportedCheck = isRecycleNotSupported(
    `${language}_${country.toUpperCase()}`
  )
  const { t } = useI18n()
  const landingPageUrl = defaultLandingPageURL(props.stack, country, language)
  const xCorrelationId = ''
  const {
    v1: { navigation } = {
      navigation: null
    }
  } = props.shell
  const enrollmentStatusApiCall = useEnrollmentStatusApiCall({
    authProvider,
    deviceId,
    stack,
    xCorrelationId
  })
  const handleRedeemNow = () => {
    // Its a new user (no projectId on params), redirecting to /designer ValueProp page
    history.replace(
      `/instantink-enroll?printerUUID=${deviceId}&redirectUri=${history.location.pathname}&analyticsBaseScreenPathName=react-smb-printer-enrollment-status`
    )
    //history.push(`${base}/${props.printer.deviceId}`)
  }
  const handleEnrollNow = () => {
    history.replace(
      `/instantink-enroll?printerUUID=${deviceId}&redirectUri=${history.location.pathname}&analyticsBaseScreenPathName=react-smb-printer-enrollment-status`
    )
  }
  const handleSeeOverview = () => {
    if (navigation) {
      navigation.push(`/instantink/overview`)
    }
  }
  useEffect(() => {
    if (enrollmentStatusApiCall.data) {
      const enrollmentStatus = enrollmentStatusApiCall.data
      getEligibility(enrollmentStatus.eligible)
      getEnrolled(enrollmentStatus.enrolled)
      getEligibilityTrial(enrollmentStatus.eligible_for_trial)
      const remainingDays = Math.ceil(
        enrollmentStatus.oobe_incentive_remaining_seconds / 86400
      ).toString()
      getTrialRemainingDays(remainingDays)
      getTrialFreeMonths(enrollmentStatus.enrollment_kit_offer_free_months)
      getPagesPerMonth(enrollmentStatus.enrollment_kit_offer_pages)
    }
  }, [enrollmentStatusApiCall.data])

  const remainingDaysCheck = trialRemainingDays === '1'

  if (enrollmentStatusApiCall.isFetching) {
    return <LoaderWidget fullscreen />
  }
  return (
    <>
      <div className={styles['hp-instant-ink-card']}>
        <div className={styles['card-header']}>
          <div className={styles['header-content']}>
            <label data-testid="enrollment_card_header">
              {t('enrollment_status.header')}
            </label>
          </div>
        </div>
        {enrolled && eligibility && eligibileForTrial && (
          <div className={styles['content']}>
            <div className={styles['title-content']}>
              <EnrolledIcon></EnrolledIcon>
              <IIBrandLogo></IIBrandLogo>
              <Divider />
              <div className={styles['sub-status']}>
                {t('enrollment_status.status.in_trial')}
              </div>
            </div>
            <div className={styles['action-content']}>
              <Button
                appearance="primary"
                disabled={isEnabled}
                onClick={handleSeeOverview}
                data-testid="see-overview-button"
              >
                {t('enrollment_status.button.see_overview')}
              </Button>
            </div>
          </div>
        )}
        {enrolled && eligibility && !eligibileForTrial && (
          <div className={styles['content']}>
            <div className={styles['title-content']}>
              <EnrolledIcon></EnrolledIcon>
              <IIBrandLogo></IIBrandLogo>
              <Divider />
              <div className={styles['sub-status']}>
                {t('enrollment_status.status.enrolled')}
              </div>
            </div>
            <div className={styles['action-content']}>
              <Button
                appearance="primary"
                disabled={isEnabled}
                onClick={handleSeeOverview}
                data-testid="see-overview-button"
              >
                {t('enrollment_status.button.see_overview')}
              </Button>
            </div>
          </div>
        )}
        {!enrolled && eligibility && eligibileForTrial && (
          <div className={styles['content']}>
            <div className={styles['title-content']}>
              <IIGiftIcon></IIGiftIcon>
              <IIBrandLogo></IIBrandLogo>
              <Divider />
              <div className={styles['sub-status']}>
                {t('enrollment_status.status.eligible_for_trial')}
              </div>
              <div className={styles['plan-details']}>
                {t(
                  remainingDaysCheck
                    ? 'enrollment_status.plan_details_one_day_left'
                    : 'enrollment_status.plan_details',
                  {
                    trial_days: trialRemainingDays,
                    trial_months: trialFreeMonths
                  }
                )}
              </div>
            </div>
            <div className={styles['detail-content']}>
              <div className={styles['plan1']}>
                {isHpPlus == true
                  ? t(
                      supplyType == 'toner'
                        ? 'enrollment_status.plan_description1'
                        : 'enrollment_status.plan_description1_ink',
                      {
                        trial_months: trialFreeMonths
                      }
                    )
                  : t(
                      supplyType == 'toner'
                        ? 'enrollment_status.plan_description1_non_hpp'
                        : 'enrollment_status.plan_description1_non_hpp_ink',
                      {
                        trial_months: trialFreeMonths
                      }
                    )}
              </div>
              <div className={styles['plan2']}>
                {supplyType == 'toner'
                  ? t('enrollment_status.plan_description2')
                  : t('enrollment_status.plan_description2_ink')}
              </div>
              <div
                className={styles['plan2']}
                dangerouslySetInnerHTML={{
                  __html: !isRecycleNotSupportedCheck
                    ? t('enrollment_status.plan_description3_hpp_trial_ink', {
                        pages: pagesPerMonth,
                        trial_months: trialFreeMonths
                      })
                    : t(
                        'enrollment_status.plan_description3_hpp_trial_no_recycling',
                        {
                          pages: pagesPerMonth,
                          trial_months: trialFreeMonths
                        }
                      )
                }}
              />
            </div>
            <div className={styles['action-content']}>
              <Button
                appearance="primary"
                disabled={isEnabled}
                onClick={handleRedeemNow}
                data-testid="redeem-now-button"
              >
                {t('enrollment_status.button.redeem_now')}
              </Button>
              <a
                className={styles['learnMoreLink']}
                href={landingPageUrl}
                target="_blank"
                rel="noreferrer"
                data-testid="learn-more-link"
              >
                {' '}
                {t('enrollment_status.button.learn_more')}
              </a>
            </div>
          </div>
        )}
        {!enrolled && eligibility && !eligibileForTrial && (
          <div className={styles['content']}>
            <div className={styles['title-content']}>
              <IIGiftIcon></IIGiftIcon>
              <IIBrandLogo></IIBrandLogo>
              <Divider />
              <div className={styles['sub-status']}>
                {t('enrollment_status.status.eligible')}
              </div>
            </div>
            <div className={styles['detail-content']}>
              <div className={styles['plan2']}>
                {supplyType == 'toner'
                  ? t('enrollment_status.plan_description2')
                  : t('enrollment_status.plan_description2_ink')}
              </div>
              {!isRecycleNotSupportedCheck ? (
                <div
                  className={styles['plan2']}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'enrollment_status.plan_description3_no_trial_ink'
                    )
                  }}
                />
              ) : (
                <div
                  className={styles['plan2']}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'enrollment_status.plan_description3_no_trial_no_recycling'
                    )
                  }}
                />
              )}
            </div>
            <div className={styles['action-content']}>
              <Button
                appearance="primary"
                disabled={isEnabled}
                onClick={handleEnrollNow}
                data-testid="enroll-now-button"
              >
                {t('enrollment_status.button.enroll_now')}
              </Button>
              <a
                className={styles['learnMoreLink']}
                href={landingPageUrl}
                target="_blank"
                rel="noreferrer"
                data-testid="learn-more-link"
              >
                {t('enrollment_status.button.learn_more')}
              </a>
            </div>
          </div>
        )}
        {!enrolled && !eligibility && !eligibileForTrial && (
          <div className={styles['content']}>
            <div className={styles['title-content']}>
              <NoDataIcon></NoDataIcon>
              <IIBrandLogo></IIBrandLogo>
              <Divider />
              <div className={styles['sub-status']}>
                {t('enrollment_status.error.no_status')}
              </div>
            </div>
            <div className={styles['detail-content']}>
              <div className={styles['plan1']}>
                {t('enrollment_status.error.no_data')}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default EnrollmentStatus
