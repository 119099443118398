import React from 'react'
import PropTypes from 'prop-types'
import { RootProvider } from '@jarvis/react-portal-addons'
import { withGlobalUdlAnalytics } from '@jarvis/react-udl-global-analytics'
import resources from '../../assets/locale/index'
import 'regenerator-runtime'
import EnrollmentStatus from './EnrollmentStatusContent'
import { JarvisAuthProvider } from '@jarvis/web-http'
import { Stack } from '@jarvis/web-stratus-client'
export type EnrollmentStatusProps = {
  /**
   * UUID of the printer
   */
  deviceUuid: string
  /**
   * Printer Model
   */
  modelNumber: string
  /**
   * program level - hp+ , ucde
   */
  programLevel: string
  /**
   * supply type - toner or ink
   */
  supplyType: string
  domain: string
  shell: any
  source?: string
  /**
   * authentication provider object that must include the getAccessToken function
   */
  authProvider?: JarvisAuthProvider

  /**
   * enum of (dev,pie,stage,prod) used for stratus web calls
   */
  stack: Stack
}
const EnrollmentStatusMFE = (props: EnrollmentStatusProps) => {
  const {
    shell: {
      v1: { localization }
    }
  } = props
  return (
    <RootProvider localization={localization} resources={resources}>
      <EnrollmentStatus {...props} />
    </RootProvider>
  )
}

EnrollmentStatusMFE.defaultProps = {
  deviceUuid: null,
  programLevel: null,
  supplyType: 'toner',
  domain: 'SMALL_MEDIUM_BUSINESS',
  modelNumber: null,
  authProvider: null,
  stack: null
}

EnrollmentStatusMFE.propTypes = {
  deviceUuid: PropTypes.string,
  modelNumber: PropTypes.string,
  programLevel: PropTypes.string,
  supplyType: PropTypes.string,
  domain: PropTypes.string,
  authProvider: PropTypes.objectOf(PropTypes.any),
  stack: PropTypes.number
}

export default withGlobalUdlAnalytics(EnrollmentStatusMFE)
