import { AxiosResponse } from 'axios'
import { JarvisWebHttpClient } from '@jarvis/web-http/dist/jarvis-web-http-client'
import { AuthProvider, EnrollmentStatusResponse } from '../types'

export interface IComfeService {
  getEnrollmentStatus(): Promise<AxiosResponse<EnrollmentStatusResponse>>
}

export class ComfeService implements IComfeService {
  httpClient: JarvisWebHttpClient
  xCorrelationId: string

  deviceId: string
  apiName = 'comfe'
  apiVersion = '1'
  enrollmentStatusEndpoint = '/enrollment_status'

  constructor(
    authProvider: AuthProvider,
    deviceId: string,
    baseURL: string,
    xCorrelationId: string
  ) {
    this.httpClient = new JarvisWebHttpClient({
      defaultBaseURL: `${baseURL}/api/${this.apiName}/v${this.apiVersion}`,
      defaultAuthProvider: authProvider
    })
    this.xCorrelationId = xCorrelationId
    this.deviceId = deviceId
  }

  async getEnrollmentStatus(): Promise<
    AxiosResponse<EnrollmentStatusResponse>
  > {
    return this.httpClient.get({
      ...this.defaultHeaders(),
      url: `${this.enrollmentStatusEndpoint}?device_uuid=${this.deviceId}`
    })
  }

  private

  defaultHeaders() {
    return {
      headers: {
        'X-Correlation-ID': this.xCorrelationId
      }
    }
  }
}
