import styled from 'styled-components'
import IIlogo from '../../assets/images/Logo.svg'
import IIGiftIconImg from '../../assets/images/II-Gift-Icon.png'
import EnrolledIconImg from '../../assets/images/enrolled.png'
import NoDataIconImg from '../../assets/images/no-data.png'
import TruckIconImg from '../../assets/images/Icon.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  height: 553px;
  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
`
export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  height: 57px;
  background: #ffffff;
  border-radius: 12px 12px 0px 0px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`
export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px 10px 24px;
  gap: 234px;
  height: 28px;
  border-bottom: 1px solid #dbdbdb;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`
export const IIBrandLogo = styled.div`
  display: inline-flex;
  align-items: center;
  padding-left: 12px;
  gap: 10px;
  background-image: url(${IIlogo.toString()});
  background-position: right center;
  background-repeat: no-repeat;
  width: 77px;
  height: 24px;
  left: 32px;
  top: 4px;
`
export const TruckIcon = styled.img`
  src: url(${TruckIconImg.toString()});
  background-color: #9a009a;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`
export const IIGiftIcon = styled.div`
  display: inline-flex;
  background-image: url(${IIGiftIconImg.toString()});
  background-position: right center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  padding-left: 2px;
`

export const EnrolledIcon = styled.div`
  display: inline-flex;
  background-image: url(${EnrolledIconImg.toString()});
  background-position: right center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  padding-left: 2px;
`

export const NoDataIcon = styled.div`
  display: inline-flex;
  background-image: url(${NoDataIconImg.toString()});
  background-position: right center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  padding-left: 2px;
`
export const Divider = styled.div`
  width: 10px;
  height: 24px;
  font-family: 'Forma DJR Micro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #737373;
  border-right: 1px solid;
  @media (max-width: 575px) {
    display: none;
  }
`
