import React from 'react'
import EnrollmentStatusMFE from '../src/components/EnrollmentStatusContent/app'
import { name as namespace } from '../package.json'

/**
 * @function Root Main function
 * @param props
 * @returns
 */
const Root = (props) => {
  return (
    <section id={namespace}>
      <EnrollmentStatusMFE {...props} />
    </section>
  )
}

export default Root
