import { useCallback } from 'react'
import { useApiCall } from '@jarvis/react-portal-addons'
import { ComfeService } from '../lib/comfeService'
import { Stack } from '@jarvis/web-stratus-client'

export const defaultComfeURL = (stack: Stack) =>
  ({
    [Stack.dev]: 'https://gemini-pie.hpconnectedpie.com',
    [Stack.stage]: 'https://comfe-stage1.instantink.com',
    [Stack.prod]: 'https://comfe.instantink.com'
  }[stack] || 'https://comfe-pie1.instantink.com')
function useEnrollmentStatusApiCall({
  authProvider,
  deviceId,
  stack,
  xCorrelationId
}) {
  const fetchStates = useCallback(
    async (cloudId) => {
      const comfeURL = defaultComfeURL(stack)
      const comfeClient = new ComfeService(
        authProvider,
        deviceId,
        comfeURL,
        xCorrelationId
      )
      // 1 - get supply states using deviceId (devices api/devices/v1/{deviceId}/suppliesStatus)
      const enrollmentStatus = await comfeClient.getEnrollmentStatus()
      return enrollmentStatus?.data
    },
    [authProvider, stack]
  )

  return useApiCall({
    fetch: fetchStates
  })
}

export default useEnrollmentStatusApiCall
